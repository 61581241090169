:root {
    --main-grey-color: #636363;
    --font-family-new: "Nunito", sans-serif;
    --blue-color: #1073c6;
    --dark-blue-color: #043b41;
    --white-background-color: #f5f7fa;
}

.tab-listing .slick-track {
    margin-left: 0;
    display: flex;
    opacity: 1 !important;
    -webkit-transform: translateZ(0) !important;
    transform: translateZ(0) !important;
    width: auto !important;
    overflow-y: auto !important;
}


.slick-slider {
    display: block;
    position: relative;
}

.slick-slider .slick-dots li {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0 5px;
}

.slick-slider .slick-dots li button {
    padding: 0;
    /* background: var(--inputBgColor); */
    opacity: 0.5;
    min-width: 1px;
    width: 10px;
    /* height: 10px; */
    transition: all 0.5s ease-in-out;
}

.slick-slider .slick-dots li button:before {
    /* display: none; */
}

.slick-slider .slick-dots .slick-active button {
    /* width: 25px; */
    opacity: 1;
}


.tab-content {
    padding: 0px 0 0;
}

.tab-content .form-main {
    margin-top: 0;
}

.tab-listing {
    padding: 0 0 20px 0;
}

.tab-listing .slick-slide {
    padding-right: 7px;
}

.tab-listing ul {
    margin: 0 0 -19px 0;
}

.tab-listing ul li {
    list-style: none;
    display: flex;
    align-items: baseline;
    vertical-align: middle;
    color: var(--main-grey-color);
    font-weight: 500;
    font-size: 17px;
    font-family: var(--font-family-new);
    text-transform: uppercase;
    padding-right: 0px;
}

.MuiTableRow-root .MuiTableCell-head.center {
    text-align: center;
}

.tab-content {
    padding: 0px 0 0;
}

.tab-content .form-main {
    margin-top: 0;
}


.tab-listing ul li a {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    line-height: 1;
    padding-bottom: 7px;
    padding-right: 0;
    width: max-content;
    color: inherit;
    text-decoration: none;
}

.tab-listing ul li a:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 3px;
    background: var(--blue-color);
    width: 0;
    transition: all 0.5s ease-in-out;
}

.tab-listing ul li a:hover,
.tab-listing ul li a:focus,
.tab-listing ul .active {
    color: var(--blue-color);
    font-weight: 700;
}

.tab-listing ul .active:after,
.tab-listing ul li a:hover:after,
.tab-listing ul li a:focus:after {
    width: 100%;
}

.tab-content .field-left {
    width: 23%;
}

.tab-content .field-right {
    width: 77%;
}

.tab-content .field-right-image {
    width: 100%;
}

.tab-content .form-main form {
    max-width: 843px;
}

.table-link a {
    color: var(--dark-blue-color);
    text-decoration: underline;
}

.table-main-wrap {
    text-align: right;
}

.tab-listing+.table-main-wrap {
    margin: 0;
}

.table-link {
    padding-bottom: 20px;
}

.table-main-wrap .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 80px;
}

.table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 200px;
}

.table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 175px;
}

.MuiTableRow-root .MuiTableCell-root:last-child {
    min-width: 105px;
}

.MuiTableRow-root th.MuiTableCell-root {
    font-weight: 700;
}


.tab-listing .slick-next {
    position: absolute;
    right: 0;
    top: -15px;
    bottom: 0;
    height: auto;
    margin: 0;
    transform: none;
    display: none !important;
}

.tab-listing .slick-next:before {
    /* display: none; */
}

.tab-listing .slick-next:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url("../../../assets/images/right-arrow.svg");
    background-repeat: no-repeat;
    background-position: center right 30px;
    width: 80px;
    background-color: var(--white-background-color);
    background-size: 7px;
    display: none;
}

.tab-listing .slick-next.slick-disabled {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.tab-listing .slick-prev {
    display: none !important;
}

.tab-listing .slick-track {
    margin-left: 0;
    display: flex;
    opacity: 20 !important;
    transform: translate3d(0px, 0px, 0px) !important;
    width: auto !important;
    overflow-y: auto !important;
}