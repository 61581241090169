.form-main{
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    padding: 10px;
    border-radius: 10px;
    border-top: 5px solid var(--erptheme);
}

.form-field-main {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0px;
    padding-bottom: 5px;
    justify-content: space-between;
}

.form-field-fullwidth {
    width: 100%;
    padding-bottom: 20px;
}

.form-field-hafewidth {
    width: 48%;
    padding-bottom: 20px;
}
.form-button{
    /* padding-top: 20px; */
    border-top: 1px solid var(--formcontrolBtnColor);
    padding-top: 10px;
    width: 100%;
    text-align: end;
    display: flex;
    justify-content: end;
    flex-direction: row;
}
.from-button-inner{
    padding-left: 20px;
   
}

#htmlediter-main{
    border: 1px solid var(--inputBorderColor);
    border-radius: 5px;
    margin-bottom: 20px;
}



