.main {
  width: 100%;
  margin-bottom: 71px;
  /* background-color: var(--inputBgColor); */
  overflow: auto;
}

* {
  font-family: Nunito, 'sans-serif' !important;
}

body {
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  width: 100%;
  flex-direction: column;
  vertical-align: top;
}

.MuiMenuItem-root {
  font-family: var(--FontFamily_1) !important;
}

.MuiButton-contained.Mui-disabled {
  color: var(--whiteColor) !important;
}

legend {
  float: none;
}

.fromcantrol-input {
  background-color: var(--inputBgColor);
  border-radius: 10px;
}

.MuiTab-wrapper {
  font-size: 18;
  font-weight: 900;
  color: var(--ButtonColor);
}

.pagetitle {
  display: flex;
  justify-content: space-between;
}

.pagetitle-left {
  margin-top: 5px;
}

.popup-hr {
  margin: 0.5rem 0;
}

.red-dot-container {
  display: flex;
}

.red-dot {
  width: 7px;
  height: 7px;
  background-color: var(--redColor);
  border-radius: 50%;
}


.text-pointer-none {
  cursor: none;
  pointer-events: none;
}

/* 
.MuiButton-label {
  min-width: 80px;
} */

@media (max-width: 1500px) {}

@media (max-width: 1199px) {}

@media (max-width: 991px) {}

@media (max-width: 768px) {}